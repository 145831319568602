@import '../../assets/styles/theme.scss';

#onesignal-bell-container {
  @include darkTheme() {
    @include darkThemeColors();
  }

  @include lightTheme() {
    @include lightThemeColors();
  }

  z-index: 16000000 !important;

  #onesignal-bell-launcher {
    bottom: 21px;
    left: 10px;

    .onesignal-bell-launcher-button circle {
      fill: var(--color-button-purple) !important;
    }

    .onesignal-bell-launcher-message {
      background-color: var(--color-button-purple) !important;
      color: var(--color-button-purple-text);
      z-index: 16000000 !important;
      opacity: 0;
    }
  }
}

#onesignal-bell-container.onesignal-reset {
  left: unset !important;
  bottom: 75px !important;
  right: 82px !important;
}

#onesignal-slidedown-dialog #normal-slidedown {
  .slidedown-body {
    .slidedown-body-icon img {
      border-radius: 50%;
    }

    .slidedown-body-message {
      color: var(--color-text-base) !important;
      font-family: nunito;
      font-size: 1.8rem;
    }
  }

  .slidedown-footer {
    margin-top: 12px;
    font-family: nunito !important;

    #onesignal-slidedown-allow-button {
      padding: 6px;
      background-color: #9831fe;
      border-radius: 0.8rem;
    }

    #onesignal-slidedown-cancel-button {
      padding: 6px;
      color: var(--color-text-base) !important;
    }
  }
}

#onesignal-bell-container.onesignal-reset
  .onesignal-bell-launcher.onesignal-bell-launcher-bottom-left
  .onesignal-bell-launcher-message:after {
  border-right-color: var(--color-button-purple) !important;
}

#onesignal-bell-container.onesignal-reset
  .onesignal-bell-launcher.onesignal-bell-launcher-theme-default
  .onesignal-bell-launcher-button
  svg
  .foreground {
  fill: var(--color-button-purple-text) !important;
}
#onesignal-bell-container.onesignal-reset
  .onesignal-bell-launcher.onesignal-bell-launcher-theme-default
  .onesignal-bell-launcher-button
  svg
  .stroke {
  stroke: var(--color-button-purple-text) !important;
}

@media (min-width: 420px) {
  #onesignal-slidedown-dialog #normal-slidedown {
    .slidedown-footer {
      margin-top: auto;

      #onesignal-slidedown-allow-button {
        padding: auto;
      }
      #onesignal-slidedown-cancel-button {
        padding: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  #onesignal-slidedown-dialog #normal-slidedown {
    .slidedown-footer {
      #onesignal-slidedown-allow-button {
        padding: 6px 10px;
        font-weight: bold;
      }

      #onesignal-slidedown-cancel-button {
        padding: 6px 10px;
      }
    }
  }

  #onesignal-bell-container.onesignal-reset.onesignal-bell-container-bottom-left {
    bottom: 0 !important;
    left: 0 !important;
  }

  #onesignal-bell-container {
    #onesignal-bell-launcher {
      bottom: 21px;
      left: 10px;

      .onesignal-bell-launcher-message {
        opacity: 1;
      }
    }
  }
}
