@mixin popupNewsletterBlogPost() {
  div.rdstation-popup-js-overlay ~ div.rdstation-popup-position-center {
    & > div.bricks--component-popup {
      border-radius: 20px !important;
      background-color: #ffffff !important;

      & .bricks--close--button {
        top: -5px !important;
        right: -5px !important;
        width: 21px !important;
        height: 21px !important;
        background: #7417d9 0% 0% no-repeat padding-box !important;
        border-radius: 100px;

        & svg {
          width: 7px !important;
          height: 7px !important;
          fill: #ffffff !important;
        }
      }

      & > section > div[id^='rd-row-'] {
        & .bricks--column > div {
          border-radius: 20px;
        }
        & .bricks--column .bricks--component-text p span,
        & label {
          font-family: 'nunito' !important;
          color: #5a6570;
        }

        & p strong {
          font-family: 'nunito' !important;
        }
      }

      & .popup-footer {
        & p,
        input {
          font-family: 'nunito' !important;
          color: #5a6570;
        }
        & a strong {
          font-family: 'nunito' !important;
        }
      }
    }
  }
}
