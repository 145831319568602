#menu-section {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: background-color 0.3s ease-in-out;
}

#menu-section #wrapper-instagram {
  order: 2;
}

#menu-section.sticky #wrapper-instagram {
  display: none;
}

#menu-section.sticky {
  background-color: var(--color-navbar-menu-background-sticky);
  z-index: 20;
  box-shadow: 0 0.2rem 2rem var(--color-button-shadow);
  padding-bottom: 0.2rem;
  padding-top: 0;
}

#navbar > .container {
  padding: 0 15px;
}

#navbar .navbar-brand img {
  max-width: 19rem;
}

#navbar .navbar-toggler {
  border: 0;
  font-size: 2rem;
}

#navbar .nav-item .dropdown-toggle::after {
  display: none;
}

#navbar .nav-item .dropdown-toggle svg {
  margin-left: 0.5rem;
}

#navbar .nav-item {
  align-self: flex-end;
  font-weight: bold;
  margin-right: 0.5rem;
}

#navbar .nav-item a {
  text-align: right;
}

#navbar .nav-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#navbar .dropdown-menu {
  margin: 0px;
  font-size: 1.6rem;
  border: 0;
  background-color: transparent;
}

#navbar.navbar-light .navbar-nav .nav-link {
  color: var(--color-navbar-menu-item);
}

#navbar.navbar-light .navbar-nav .nav-link:hover,
#navbar.navbar-light .navbar-nav .nav-link:active,
#navbar.navbar-light .navbar-nav .nav-link:focus,
#navbar.navbar-light .navbar-nav .nav-link.active {
  color: var(--color-navbar-menu-item-hover);
}

#navbar .dropdown-menu a.dropdown-item {
  padding: 0.5rem 0.25rem;
  background-color: inherit;
  color: var(--color-navbar-dropdown-menu-item);
  transition: opacity 0.1s ease-in-out;
}

#navbar .dropdown-menu a.dropdown-item:hover,
#navbar .dropdown-menu a.dropdown-item:focus,
#navbar .dropdown-menu a.dropdown-item:active {
  color: var(--color-navbar-dropdown-menu-item-hover);
}

#menu-section #navbar .dropdown-menu a.dropdown-item {
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 0.8rem;
}

#menu-section #navbar .dropdown-menu a.dropdown-item:last-of-type {
  margin-bottom: 0;
  margin-right: 13px;
}

#navbar .dropdown-menu a.dropdown-item:hover,
#navbar .dropdown-menu a.dropdown-item:focus {
  background-color: transparent;
}

#navbar .dropdown-menu a.dropdown-item.active {
  color: var(--color-navbar-dropdown-menu-item-active);
}

#menu-section .navbar-toggler {
  background-color: var(--color-navbar-toggler-background);
}

.nav-item .btn-falar-consultor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  #menu-section.sticky {
    box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
  }
}

@media (min-width: 992px) {
  #menu-section .container {
    max-width: 1125px;
  }

  #navbar .nav-item {
    align-self: center;
    margin: 0 1rem;
  }

  #navbar .nav-item a {
    text-align: left;
  }

  #navbar .nav-item > button,
  #navbar .nav-item > .btn {
    font-weight: bold;
    font-size: 1.6rem;
  }

  #navbar .nav-item > button,
  #navbar .nav-item > .btn svg {
    margin-left: 15px;
  }

  #navbar .nav-actions {
    align-self: center;
    flex-direction: row;
    margin-left: 3rem;
  }

  #navbar .nav-buttons {
    align-self: center;
    flex-direction: row;
    margin-left: 0rem;
  }

  #navbar .nav-actions .nav-item {
    margin: 0;
    align-self: stretch;
  }

  #navbar .nav-actions button.btn.btn-secondary {
    font-size: 1.3rem;
  }

  #navbar .nav-buttons button.btn.btn-secondary {
    font-size: 1.3rem;
  }

  #navbar .dropdown-menu {
    padding: 1rem;
    z-index: 1;
    transition: background-color 0.4s ease-in-out;
    border-radius: 1rem;
  }

  #menu-section #navbar .dropdown-menu {
    background-color: var(--color-navbar-dropdown-menu);
    box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
    padding: 2.4rem;
    margin-top: 1.4rem !important;
  }

  #menu-section #navbar .navbar-nav > div:last-of-type .dropdown-menu {
    right: 0;
  }
}

@media (min-width: 1200px) {
  #menu-section .container {
    max-width: 1309px;
  }
}
