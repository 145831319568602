@import 'dark-theme';

@mixin theme() {
  & {
    background-color: var(--color-background);
  }
  &,
  & input,
  & button,
  & textarea {
    font: 500 1.6rem 'Nunito', sans-serif;
    color: var(--color-text-base);
  }

  & h1,
  & .h1 {
    font-size: var(--h1-font-size);
  }

  & .suspense {
    min-height: 200vh;
    background-color: var(--color-background);
  }
}

@mixin darkTheme() {
  & .dark-theme {
    @content;
    @include theme;
    @include darkThemeContent;
  }
}

@mixin darkThemeColors() {
  --color-background: #050505;
  --color-background-off: #0f0e0d;
  --color-text-base: white;
  --color-text-base-hover: #f8f8f8;
  --color-separator: #a59a8f1a;
  --color-button-shadow: #1d1f1cde;
  --color-button-primary: #ffffff;
  --color-button-primary-hover: #f8f8f8;
  --color-button-primary-text: #5a6570;
  --color-button-secondary: #0c643d;
  --color-button-secondary-hover: #0d4a34;
  --color-button-secondary-text: #ffffff;
  --color-button-tertiary: #9a0ea4;
  --color-button-tertiary-hover: #7b1183;
  --color-button-tertiary-text: #f1f1f1;
  --color-button-purple: #eaff00;
  --color-button-purple-hover: #eaff00;
  --color-button-purple-text: #050505;
  --color-button-purple-text-hover: #050505;
  --color-purple: #bd7bfe;
  --color-purple-hover: #9831fe;
  --color-green: #0c643d;
  --color-white: #ffffff;
  --color-white-off: #f0f0f06e;
  --color-pink: #ed7ff5;
  --color-feature-links: #f2f2f2;
  --color-yellow: yellow;
  --color-yellow-off: #eaff00;
  --color-hover-yellow: #ffcd2d;
  --color-pink-text: #ed7ff5;
  --color-title-awards: #8e31f4;
  --color-border-input: #e6e9ed;
  --color-border-input-error: #fa5757;
  --color-placeholder-input: #5a65708a;
  --color-success: #008b4f;
  --h1-font-size: 4rem;
  --text-highlight-color: #9731fe4d;
  --color-navbar-menu-background: rgba(0, 0, 0, 0.8);
  --color-navbar-menu-background-mobile: #000;
  --color-navbar-menu-background-sticky: #0f0e0d;
  --color-navbar-toggler-background: #353433;
  --color-navbar-dropdown-menu: #0f0e0d;
  --color-navbar-dropdown-menu-item: white;
  --color-navbar-dropdown-menu-item-active: yellow;
  --color-navbar-dropdown-menu-item-hover: yellow;
  --color-navbar-menu-item: white;
  --color-navbar-menu-item-hover: yellow;
  --color-footer: #000;
  --color-card-segment-card: #a59a8f1a;
  --color-functionality-card-hover: #a59a8f1a;
  --color-functionality-card: rgb(15, 14, 13);
  --color-container-blog: #0f0e0d;
  --color-paper-container-rounder: #0f0e0d;
  --color-mid-section-card: #0f0e0d;
  --color-play-button-background: #353433;
  --color-play-button-background-hover: #494847;
  --color-carousel-button-background: #353433;
  --color-title-hr: #a59a8f;
  --color-steppers-icon-complete-background: unset;
  --color-steppers-icon-waiting-background: #dcdcdc;
  --outline-color: --color-text-base;
  --color-table-header: #000;
  --color-background-instagram: rgba(0, 0, 0, 0.8);
  --color-border-instagram: yellow;
  --background-home-features: transparent;
  --background-home-plans: #a59a8f1a;
  --color-gray: #000;
  --color-gray-light: #000;
  --color-gray-dark: #000;
  --color-pink-primary: #eaff00;
  --color-primary-text: #000;
  --icon-check: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 200 146' style='enable-background:new 0 0 200 146;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAFE00;%7D%0A%3C/style%3E%3Cpath class='st0' d='M69,142.3c-3.5,0-6.9-1.4-9.4-3.9L8.4,87.1c-5.2-5.2-5.2-13.5,0-18.7c5.2-5.2,13.5-5.2,18.7,0L69,110.3 L172.4,6.9c5.2-5.2,13.5-5.2,18.7,0c5.2,5.2,5.2,13.5,0,18.7L78.3,138.4C75.9,140.9,72.5,142.3,69,142.3z'/%3E%3C/svg%3E");
  --arrow-plans: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 135 116' style='enable-background:new 0 0 135 116;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAFF00;%7D%0A%3C/style%3E%3Cpath id='Path_214' class='st0' d='M83,109.3c0.3-0.2,0.6-0.5,0.9-0.8L129,63.5c3.5-3.5,3.5-9.2,0-12.7l0,0L83.9,5.6 c-3.5-3.5-9.2-3.5-12.7,0c-3.4,3.4-3.4,8.9,0,12.3c0.2,0.2,0.4,0.3,0.5,0.5l24.9,25c1.3,1.3,2.8,2.4,4.5,3.3l2.7,1.2H15.3 c-4.6-0.2-8.6,3-9.5,7.6c-0.8,4.9,2.5,9.5,7.5,10.3c0.5,0.1,1,0.1,1.6,0.1h88.9l-1.9,0.9c-1.9,0.9-3.6,2.1-5.1,3.6L71.7,95.4 c-3.3,3.1-3.8,8.2-1.3,11.9C73.3,111.4,79,112.2,83,109.3z'/%3E%3C/svg%3E");
  --icon-check-wpp: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 200 146' style='enable-background:new 0 0 200 146;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M69,142.3c-3.5,0-6.9-1.4-9.4-3.9L8.4,87.1c-5.2-5.2-5.2-13.5,0-18.7c5.2-5.2,13.5-5.2,18.7,0L69,110.3 L172.4,6.9c5.2-5.2,13.5-5.2,18.7,0c5.2,5.2,5.2,13.5,0,18.7L78.3,138.4C75.9,140.9,72.5,142.3,69,142.3z'/%3E%3C/svg%3E");
  --color-box-shadow: #f1f1f1;
  --color-button-recomendado: yellow;
  --color-recomendado: #000;
  --planos-container-title: #ffffff;
  --planos-container: yellow;
  --planos-modal-border: yellow;
  --color-purple-title: yellow;
  --faq-item-background: #a59a8f1a;
  --faq-item-green-background: #a59a8f1a;
  --faq-item-green-active-background: #a59a8f53 0% 0% no-repeat padding-box;
  --color-button-text-purple: #000000;
  --color-background-input: #050505;
  --color-input-text-purple: yellow;
  --color-department-functionality-card-hover: #050505;
  --color-overlay-arrow-background: var(--color-button-purple);
  --color-overlay-arrow-icon: var(--color-button-text-purple);
  --color-overlay-arrow-background-no-opacity: var(--color-button-purple);
  --color-purple-accesibility: yellow;
  --color-green-whatsapp: #eaff00;
  --color-green-dark: #eaff00;
  --color-green-light: #fff;
  --color-background-blackfriday: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(84, 25, 145, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

@mixin lightTheme() {
  & .light-theme {
    @content;
    @include theme;
  }
}

@mixin lightThemeColors() {
  --color-background: #fafbfc;
  --color-background-off: #f0f1f2;
  --color-text-base: #5a6570;
  --color-text-base-hover: #5a6570de;
  --color-separator: rgba(90, 101, 112, 0.1);
  --color-button-shadow: rgba(38, 7, 66, 0.1);
  --color-button-primary: #ffffff;
  --color-button-primary-hover: #f8f8f8;
  --color-button-primary-text: #5a6570;
  --color-button-secondary: #00d473;
  --color-button-secondary-hover: #00c166;
  --color-button-secondary-text: #ffffff;
  --color-button-tertiary: #f057fa;
  --color-button-tertiary-hover: #e047ea;
  --color-button-tertiary-text: #f1f1f1;
  --color-button-purple: #9831fe;
  --color-button-purple-hover: #7725c9;
  --color-button-purple-text: #ffffff;
  --color-button-purple-text-hover: #ffffff;
  --color-purple: #9831fe;
  --color-purple-hover: #7725c9;
  --color-green: #00d473;
  --color-white: #ffffff;
  --color-white-off: #f0f0f06e;
  --color-pink: #f057fa;
  --color-feature-links: #f2f2f2;
  --color-hover-yellow: #ffcd2d;
  --color-pink-text: #e657f0;
  --color-title-awards: #8e31f4;
  --color-border-input: #e6e9ed;
  --color-border-input-error: #fa5757;
  --color-placeholder-input: #5a65708a;
  --color-success: #008b4f;
  --h1-font-size: 4rem;
  --text-highlight-color: #9731fe4d;
  --color-navbar-menu-background: unset;
  --color-navbar-menu-background-mobile: #fafafa;
  --color-navbar-menu-background-sticky: #fff;
  --color-navbar-toggler-background: #8725ff;
  --color-navbar-dropdown-menu: #fff;
  --color-navbar-dropdown-menu-item: rgba(33, 37, 41, 0.5);
  --color-navbar-dropdown-menu-item-active: rgba(0, 0, 0, 0.9);
  --color-navbar-dropdown-menu-item-hover: rgba(33, 37, 41, 0.7);
  --color-navbar-menu-item: rgba(0, 0, 0, 0.5);
  --color-navbar-menu-item-hover: rgb(0, 0, 0, 0.7);
  --color-footer: #fff;
  --color-card-segment-card: #fff;
  --color-functionality-card-hover: #9831fe;
  --color-functionality-card: #fff;
  --color-container-blog: #fff;
  --color-paper-container-rounder: #fff;
  --color-mid-section-card: #fff;
  --color-play-button-background: #fff;
  --color-play-button-background-hover: #f5f2f2;
  --color-carousel-button-background: #fff;
  --color-title-hr: rgba(0, 0, 0, 0.1);
  --color-steppers-icon-complete-background: #f8f8f8;
  --color-steppers-icon-waiting-background: #5a657029;
  --outline-color: --color-text-base;
  --color-table-header: #f2f2f2;
  --color-header-instagram: #ffcc2d;
  --color-button-recomendado: #f057fa;
  --color-recomendado: #ffffff;
  --planos-container-title: #9831fe;
  --planos-container: #5a6570;
  --planos-modal-border: #fafafa;
  --faq-item-background: #ffffff 0% 0% no-repeat padding-box;
  --faq-item-green-background: #075e54 0% 0% no-repeat padding-box;
  --faq-item-green-active-background: #25d366 0% 0% no-repeat padding-box;
  --color-background-instagram: transparent
    linear-gradient(90deg, #db61f8 8%, #b042fb 40%, #8625ff 69%) 0% 0% no-repeat
    padding-box;
  --color-border-instagram: #ffffff;
  --background-home-features: transparent
    linear-gradient(
      61deg,
      #9825ff 0%,
      #9825ff 20%,
      #a029fd 49%,
      #b737fa 60%,
      #c13df9 85%,
      #ff61f0 100%
    )
    0% 0% no-repeat padding-box;
  --background-home-plans: transparent
    linear-gradient(
      61deg,
      #9825ff 0%,
      #9825ff 20%,
      #a029fd 49%,
      #b737fa 60%,
      #c13df9 85%,
      #ff61f0 100%
    )
    0% 0% no-repeat padding-box;
  --color-gray: #e9ecef;
  --color-gray-light: #e9ecef77;
  --color-gray-dark: #e3e6ea;
  --color-pink-primary: #f057fa;
  --color-primary-text: #ffffff;
  --icon-check: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 200 146' style='enable-background:new 0 0 200 146;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2300E57C;%7D%0A%3C/style%3E%3Cpath class='st0' d='M69,142.3c-3.5,0-6.9-1.4-9.4-3.9L8.4,87.1c-5.2-5.2-5.2-13.5,0-18.7c5.2-5.2,13.5-5.2,18.7,0L69,110.3 L172.4,6.9c5.2-5.2,13.5-5.2,18.7,0c5.2,5.2,5.2,13.5,0,18.7L78.3,138.4C75.9,140.9,72.5,142.3,69,142.3z'/%3E%3C/svg%3E");
  --arrow-plans: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 135 116' style='enable-background:new 0 0 135 116;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%232AED20;%7D%0A%3C/style%3E%3Cpath id='Path_214' class='st0' d='M82,109.3c0.3-0.2,0.6-0.5,0.9-0.8L128,63.5c3.5-3.5,3.5-9.2,0-12.7l0,0L82.9,5.6 c-3.5-3.5-9.2-3.5-12.7,0c-3.4,3.4-3.4,8.9,0,12.3c0.2,0.2,0.4,0.3,0.5,0.5l24.9,25c1.3,1.3,2.8,2.4,4.5,3.3l2.7,1.2H14.3 c-4.6-0.2-8.6,3-9.5,7.6c-0.8,4.9,2.5,9.5,7.5,10.3c0.5,0.1,1,0.1,1.6,0.1h88.9l-1.9,0.9c-1.9,0.9-3.6,2.1-5.1,3.6L70.7,95.4 c-3.3,3.1-3.8,8.2-1.3,11.9C72.3,111.4,78,112.2,82,109.3z'/%3E%3C/svg%3E");
  --icon-check-wpp: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 200 146' style='enable-background:new 0 0 200 146;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M69,142.3c-3.5,0-6.9-1.4-9.4-3.9L8.4,87.1c-5.2-5.2-5.2-13.5,0-18.7c5.2-5.2,13.5-5.2,18.7,0L69,110.3 L172.4,6.9c5.2-5.2,13.5-5.2,18.7,0c5.2,5.2,5.2,13.5,0,18.7L78.3,138.4C75.9,140.9,72.5,142.3,69,142.3z'/%3E%3C/svg%3E");
  --color-box-shadow: #00000029;
  --color-purple-title: var(--color-purple);
  --color-button-text-purple: #9831fe;
  --color-background-input: white;
  --color-input-text-purple: #8725ff;
  --color-department-functionality-card-hover: #9831fe;
  --color-overlay-arrow-background: var(--color-button-shadow);
  --color-overlay-arrow-icon: var(--color-text-base);
  --color-overlay-arrow-background-no-opacity: #ededed;
  --color-purple-accesibility: #9830fd;
  --color-green-whatsapp: #0ce47b;
  --color-green-dark: #14d87a;
  --color-green-light: #0ce47b;
  --color-background-blackfriday: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(84, 25, 145, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

body {
  @include darkTheme() {
    @include darkThemeColors();
  }

  @include lightTheme() {
    @include lightThemeColors();
  }
}
