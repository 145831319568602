.navbar-collapse.show,
.navbar-collapse.collapsing {
  display: flex;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: flex-start;
  transition: all 0.2s ease-in-out;
}

.navbar-collapse.collapsing {
  min-height: 1px;
  height: 1px !important;
  display: none;
}

.navbar-collapse.show {
  min-height: calc(100vh - 68px);
  height: calc(100vh - 68px);
}

.navbar-collapse.show .ml-auto.navbar-nav,
.navbar-collapse.collapsing .ml-auto.navbar-nav {
  margin-top: 3rem;
  margin-right: auto;
}

#navbar .navbar-collapse.show .nav-item,
#navbar .navbar-collapse.collapsing .nav-item {
  margin-right: 0;
  font-size: 2rem;
  align-self: center;
  margin-bottom: 2rem;
}

#navbar .navbar-collapse.show .nav-item a,
#navbar .navbar-collapse.collapsing .nav-item a {
  text-align: center;
}

#menu-section {
  background-color: var(--color-navbar-menu-background);
}

#menu-section.fixed {
  background-color: var(--color-navbar-menu-background-mobile);
  position: fixed;
  z-index: 16000010;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
#menu-section .navbar-toggler {
  background-color: var(--color-navbar-toggler-background);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

#navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'%3E%3Cpath fill='%23666E6E' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E") !important;
  background-size: 150%;
}

@media (min-width: 992px) {
  .navbar-collapse.show {
    min-height: initial;
    height: initial;
    width: initial;
  }

  #menu-section.fixed {
    background-color: inherit;
    position: fixed;
    padding: 1.4rem 0;
    top: 0;
    z-index: 10;
    transition: background-color 0.3s ease-in-out;
    overflow: inherit;
    height: initial;
  }

  #menu-section.fixed.sticky {
    background-color: white;
    z-index: 20;
    box-shadow: 0 0.9rem 2rem var(--color-button-shadow);
    padding-bottom: 0.2rem;
    padding-top: 0;
  }

  .navbar-collapse.show .ml-auto.navbar-nav {
    margin-top: 0;
    margin-right: 0;
  }

  #navbar .navbar-collapse.show .nav-item,
  #navbar .navbar-collapse.collapsing .nav-item {
    align-self: center;
    margin: 0 1rem;
    font-size: 1.6rem;
  }

  #navbar .navbar-collapse.show .nav-item a,
  #navbar .navbar-collapse.collapsing .nav-item a {
    text-align: left;
  }
}
