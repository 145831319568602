.blog-brand-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-brand-container figure {
  max-width: 11.3rem;
}

.blog-brand-container span {
  color: var(--color-text-base);
  opacity: 0.2;
  display: flex;
  font-size: 3rem;
  font-weight: bold;
}

.blog-brand-container span::before {
  content: '';
  margin: 0.8rem 1.5rem 1rem;
  width: 0.1rem;
  background: var(--color-text-base);
}

#navbar.navbar-blog .nav-link {
  color: inherit !important;
}

#navbar.navbar-blog .dropdown-menu a.dropdown-item {
  opacity: 0.9;
}

#navbar.navbar-blog .dropdown-menu a.dropdown-item.active,
#navbar.navbar-blog .dropdown-menu a.dropdown-item:active,
#navbar.navbar-blog .dropdown-menu a.dropdown-item:hover,
#navbar.navbar-blog .dropdown-menu a.dropdown-item:focus,
#navbar.navbar-blog .nav-link.active,
#navbar.navbar-blog .nav-link:active,
#navbar.navbar-blog .nav-link:hover,
#navbar.navbar-blog .nav-link:focus {
  color: var(--color-pink-text) !important;
}

#navbar.navbar-blog .loading-menu {
  align-self: flex-end;
  margin-right: 1rem;
}

@media (min-width: 992px) {
  #navbar.navbar-blog .loading-menu {
    margin-right: 6rem;
    margin-top: -1.5rem;
  }
  .navbar-expand-lg .navbar-nav {
    justify-content: center;
    align-items: center;
  }
}
