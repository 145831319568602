@mixin popupNewsletterBlog() {
  div.rdstation-popup-position-bottom_right:not(.floating-button) {
    & div.bricks--component-popup {
      border-radius: 20px !important;
      background: #a125ff 0% 0% no-repeat padding-box;

      & .bricks--close--button {
        top: 10px !important;
        left: 9px !important;
        width: 21px !important;
        height: 21px !important;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border-radius: 100px;

        & svg {
          width: 7px !important;
          height: 7px !important;
          fill: #7417d9 !important;
        }
      }
      & .bricks--column > div {
        height: 94% !important;
      }

      & strong {
        font: normal normal bold 35px/40px Nunito !important;
      }

      & .bricks--component-text p span {
        font: normal normal bold 18px Nunito !important;
        width: 100%;
      }

      & section {
        border-radius: 20px !important;

        & > div[id^='rd-row-'],
        & > div[id^='rd-row-']:last-of-type > div > div {
          background: #a125ff 0% 0% no-repeat padding-box !important;
        }

        & > div[id^='rd-row-']:first-of-type {
          border-radius: 20px 20px 0 0 !important;

          & > div[id^='rd-column-']:first-of-type > div {
            border-radius: 20px !important;
            margin: 15px !important;
          }
        }

        & > div[id^='rd-row-']:last-of-type,
        & > div[id^='rd-row-']:last-of-type > div > div {
          border-radius: 0 0 20px 20px !important;
        }

        & > div[id^='rd-row-']:last-of-type > div > div {
          & > div {
            max-width: unset !important;
            height: 100%;
          }
          & > a {
            font: normal normal bold 15px Nunito !important;
            width: 40% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}
