#floating_btn_whatsapp{
  width: 60px;
  height: 60px;
}

@media (max-width: 768px) {
  #floating_btn_whatsapp{
    width: 45px;
    height: 45px;
  }
}
