@mixin popupExitBrowser() {
  button#rd-close_button-l65fp9ln {
    top: 5px !important;
    right: 5px !important;
    background: #fff 0% 0% no-repeat padding-box !important;

    & svg {
      width: 7px !important;
      height: 7px !important;
      fill: #7417d9 !important;
    }
  }

  section#rd-section-l65fp9lp {
    border-radius: 30px !important;

    & div[id^='rd-text-'] {
      & span {
        font-family: Nunito !important;
        font-weight: bold;
      }
    }

    & label.bricks-form__label {
      font-family: Nunito !important;
    }

    & div.bricks-form__field.bricks-form__terms {
      & > p {
        font-family: Nunito !important;
      }
    }

    & div.form-control.phone-country {
      background-color: rgba($color: #fff, $alpha: 0.15);
      border-radius: 10px 0 0 10px !important;
    }

    & p.bricks-form__static > a {
      color: #fff !important;
    }

    & div.bricks-form__submit {
      & > button {
        font-family: Nunito !important;
      }
    }

    & input.bricks-form__input {
      background-color: rgba($color: #fff, $alpha: 0.15);
      color: #fff !important;
    }

    & input.bricks-form__input::placeholder {
      color: #fff;
    }

    & div.choices.rd-choices {
      background-color: transparent;
      & div.choices__inner {
        background-color: rgba($color: #fff, $alpha: 0.15) !important;
        border-top-left-radius: 10px !important;
      }
    }

    & button.bricks--component-button {
      box-shadow: 0px 15px 12px #00000029;
    }
    & #rd-text-l65fp9ls > h3 > span {
      font-size: 15px !important;
    }
    & #rd-text-l65fp9lt > h4 > strong {
      font-size: 11px !important;
    }
  }
}
