#footer-section {
  padding-top: 5rem;
  margin-top: 0rem;
  padding-bottom: 3rem;
  background-color: var(--color-footer);
  overflow: hidden;
}

.footer-layout {
  display: flex;
  flex-direction: column;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-menu .footer-menu-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 1rem 2.5rem 1rem;
  padding-right: 0.5rem;
}

.footer-menu a {
  margin: 0.5rem 0;
  transition: all 0.3s linear;
}

.footer-menu .footer-menu-column a:hover,
.footer-menu .footer-menu-column a:focus {
  opacity: 0.7;
}

.footer-menu .footer-menu-column h2 {
  color: var(--color-purple);
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.footer-menu .footer-menu-column h2 + h2 {
  margin-top: 3.5rem;
}

.footer-contact-address {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid var(--color-separator);
  padding-top: 3rem;
}

.footer-contact {
  margin-right: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

.footer-contact h2 {
  font-weight: bold;
}

.footer-contact a {
  margin: 0 1rem;
}

.footer-contact a:first-of-type {
  margin-left: 0;
}

.footer-contact a:last-of-type {
  margin-right: 0;
}

.footer-contact .footer-social-media-row {
  margin: 1rem 0;
}

.footer-contact .footer-social-media-row svg {
  width: 32px;
  height: 32px;
}

.footer-contact .footer-social-media-row svg + svg {
  margin-left: 1rem;
}

.footer-address {
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

.footer-address h2 {
  font-weight: bold;
}

.footer-address h3 {
  font-size: 1.9rem;
}

.footer-bottom {
  padding-top: 2rem;
  margin-top: 4rem;
  border-top: solid 1px var(--color-separator);
}

.footer-bottom .footer-bottom-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-bottom .footer-bottom-column .footer-logo img {
  min-width: 10rem;
  max-width: 19rem;
  max-height: 4.8rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.footer-bottom .footer-bottom-column .footer-privacy-terms {
  font-size: 1.2rem;
  padding: 0 1rem;
  margin-bottom: 0;
}

.footer-bottom .footer-bottom-column .footer-privacy-terms span {
  color: var(--color-purple);
}

.b2b-stack {
  width: 60%;
  padding-top: 1rem;
}

@media (min-width: 510px) {
  #footer-section {
    padding-bottom: 0;
  }

  .footer-bottom .footer-bottom-column {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (min-width: 590px) {
  .footer-menu {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  #footer-section {
    padding-top: 64px;
  }
}

@media (min-width: 992px) {
  .footer-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .footer-menu {
    flex: 1 0 50%;
    margin-right: 5rem;
  }

  .footer-contact-address {
    flex-direction: column;
    padding-left: 5rem;
    border-left: solid 1px var(--color-separator);
    border-top: 0;
    padding-top: 0;
  }
}

@media (min-width: 1600px) {
  .footer-menu {
    flex: 1 0 auto;
  }
}
