@keyframes openLanguageMenu {
  0% {
    top: 0;
    visibility: visible;
  }
  100% {
    top: 80%;
  }
}

@keyframes openLanguageMenuSticky {
  0% {
    top: 0;
    visibility: visible;
  }
  100% {
    top: 100%;
  }
}

@keyframes closeMenu {
  0% {
    top: 80%;
    opacity: 1;
    visibility: visible;
  }

  100% {
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes closeMenuSticky {
  0% {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }

  100% {
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
}

#menu-section #navbar .nav-item.languages {
  z-index: 0;
}

#menu-section #navbar .nav-item.languages .dropdown-menu {
  min-width: unset;
  padding: 0 1rem;
  border-radius: 0 0 1rem 1rem;
  opacity: 0;
  top: 0;
  z-index: -1;
  display: block;
  visibility: hidden;
}

#menu-section #navbar .nav-item.languages.closing .dropdown-menu {
  animation: closeMenuSticky 0.3s alternate linear;
}

#menu-section:not(.sticky) #navbar .nav-item.languages .dropdown-menu {
  background-color: transparent;
  padding: 0 0 0 1rem;
  box-shadow: none;
  margin-top: 0 !important;
}

#menu-section:not(.sticky) #navbar .nav-item.languages.closing .dropdown-menu {
  animation: closeMenu 0.3s alternate linear;
}

#menu-section #navbar .nav-item.languages .dropdown-menu.show {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

#menu-section #navbar .nav-item.languages.showing .dropdown-menu.show {
  animation: openLanguageMenuSticky 0.3s alternate linear;
}

#menu-section:not(.sticky) #navbar .nav-item.languages .dropdown-menu.show {
  top: 80%;
  left: 2%;
}

#menu-section:not(.sticky)
  #navbar
  .nav-item.languages.showing
  .dropdown-menu.show {
  animation: openLanguageMenu 0.3s alternate linear;
}

#menu-section #navbar .nav-item.languages .dropdown-menu .dropdown-item {
  padding-left: 0;
  width: fit-content;
  transition: unset;
}
