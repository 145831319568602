@mixin darkThemeContent() {
  & * {
    color: white;
  }

  & p span {
    color: white !important;
  }

  & .container-txt * {
    color: white !important;
  }

  & #menu-section {
    border-bottom: 1px solid var(--color-yellow) !important;
  }

  & .container-txt a,
  & .container-txt a * {
    color: var(--color-yellow) !important;
    text-decoration: underline !important;
  }

  & h2,
  & h2 * {
    color: var(--color-yellow) !important;
  }

  & .blog-header-container * {
    color: white !important;
    opacity: 1 !important;
  }

  & #mktzap-demonstration img,
  & #banner-container .logo-variant img,
  & .blog-header-container img,
  & .home-mktzap-intro img,
  & .about-newway .title img,
  & #jobs-mid-section-card .logo-section img,
  & #planos-page .planos-mktzap-intro .mktzap-text-image,
  & #mktzap-page .mktzap-header img.mktzap-text-image,
  & #mktzap-page img.mktzap-intro-image,
  & #whatsapp-page img.mktzap-image {
    filter: saturate(1) grayscale(1) brightness(10);
  }

  & .whatsapp-redirect-text-image path#Path_59,
  & .whatsapp-redirect-text-image path#Path_60,
  & .whatsapp-redirect-text-image path#green {
    fill: white;
  }
  & #our-customers .container-logo .logo-figure img {
    filter: saturate(0) grayscale(0) invert(1) brightness(1);
  }

  & .footer-bottom img,
  & header .navbar-brand img,
  & #free-trial-mktzap-logo img & #jobs-mid-section-card img,
  & #brand-logo .content img,
  & #whatsapp-link-generator-page .whatsApp-form > img,
  & #our-customers .container-logo .logo-figure:nth-child(n + 3) img {
    filter: saturate(1) grayscale(1) invert(1) brightness(10);
  }

  & .body-free-trial {
    background-image: none;
  }

  #free-trial-mktzap-logo img {
    filter: saturate(1) grayscale(1) invert(1) brightness(10);
  }

  & #donate-institute img {
    filter: saturate(1) grayscale(1) invert(1) brightness(3);
  }

  & #logo-figure * {
    filter: saturate(1) invert(1) brightness(200%);
    opacity: 1;
  }

  & #jobs-mid-section-card h2,
  & #jobs-mid-section-card span {
    color: white !important;
    opacity: 1 !important;
  }

  & .icon *,
  & .icon *:before {
    fill: var(--color-yellow) !important;
    color: var(--color-yellow) !important;
  }

  & .btn-primary,
  & .btn-primary:hover,
  & .btn-primary:active,
  & .btn-primary:focus,
  & .btn-primary *,
  & .btn-secondary,
  & .btn-secondary:hover,
  & .btn-secondary:active,
  & .btn-secondary:focus,
  & .btn-secondary *,
  & .btn-custom,
  & .btn-custom:hover,
  & .btn-custom:active,
  & .btn-custom:focus,
  & .btn-custom *,
  & .btn-tertiary,
  & .btn-tertiary:hover,
  & .btn-tertiary:active,
  & .btn-tertiary:focus,
  & .btn-tertiary * {
    background: var(--color-yellow-off) !important;
    color: black !important;
  }

  & .community-numbers h2 {
    opacity: 1;
  }

  & #features,
  & #scheduling-header-v2 {
    background: black;
  }

  & #features h3,
  & #features h3 *,
  & #features h4 {
    color: var(--color-yellow);
  }

  & .number-info-row * {
    color: white;
  }

  & .all-content-container * {
    color: var(--color-yellow-off) !important;
    text-decoration: underline;
  }

  & .all-content-container:hover a {
    color: var(--color-yellow-off) !important;
  }

  & .post-link,
  & .post-link * {
    color: var(--color-yellow) !important;
    text-decoration: underline;
  }

  & #menu-section .navbar-toggler {
    background: var(--color-yellow);
  }

  & footer * {
    color: white !important;
    opacity: 1;
  }

  & footer a,
  & label[for='accept'] a {
    color: var(--color-yellow-off) !important;
    text-decoration: underline;
  }

  & .paper-container-rounder .container-scheduling {
    background: none !important;
  }

  & .paper-container-rounder:before {
    background: none !important;
    box-shadow: none !important;
    display: none !important;
  }

  & #banner-container {
    background: none;
    box-shadow: unset;
  }

  & #material-header {
    background-image: none;
    box-shadow: unset;
  }

  & #blog-page #materials .container::after {
    background-image: unset;
  }

  & .post > .content .content-blog h3 {
    color: var(--color-yellow);
  }

  & .post > .content .content-blog a,
  & .post > .content .content-blog a strong {
    color: var(--color-yellow);
    text-decoration: underline;
  }

  & .blog-brand-container span {
    opacity: 1;
  }

  & #features .feature-detail-top,
  & #features .feature-detail {
    background-image: unset;
  }

  & #chat-simulation .chat-message-wrapper .chat-message-robot .chat-message p {
    color: #050505;
  }

  & .label.tertiary,
  & #channelsCarousel .icon-balloon {
    background-color: var(--color-yellow);
    color: #050505;
  }

  & #channelsCarousel .icon-balloon::before {
    border-top-color: var(--color-yellow);
  }

  & .loading-title,
  & .loading-title *,
  & .loading-subtitle,
  & .loading-subtitle * {
    color: transparent !important;
  }

  & #navbar.navbar-blog .dropdown-menu a.dropdown-item.active,
  & #navbar.navbar-blog .dropdown-menu a.dropdown-item:active,
  & #navbar.navbar-blog .dropdown-menu a.dropdown-item:hover,
  & #navbar.navbar-blog .dropdown-menu a.dropdown-item:focus,
  & #navbar.navbar-blog .nav-link.active,
  & #navbar.navbar-blog .nav-link:active,
  & #navbar.navbar-blog .nav-link:hover,
  & #navbar.navbar-blog .nav-link:focus {
    color: var(--color-yellow) !important;
  }

  & input,
  & select {
    background-color: #050505 !important;
  }

  & input::placeholder,
  & select::placeholder {
    opacity: 1 !important;
    color: var(--color-white) !important;
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px rgb(54, 54, 54) inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  & .play-button.btn:hover .play-button-icon,
  & .play-button.btn:focus .play-button-icon,
  & .play-button.btn:active .play-button-icon {
    box-shadow: unset;
  }

  & .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  & #navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7' ><path opacity='.6' fill='%23666E6E' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'/></svg>") !important;
  }

  & #cookie-consent a,
  & #cookie-consent a strong {
    color: var(--color-yellow);
    text-decoration: underline;
  }

  & #cookie-consent #personalized-accept-button {
    color: var(--color-yellow) !important;
  }

  & #navbar.navbar-light .navbar-nav .nav-link:hover a:not(.btn),
  & #navbar.navbar-light .navbar-nav .nav-link.active a:not(.btn) {
    color: var(--color-yellow) !important;
  }

  & .clear-button.btn-primary {
    background: none !important;
  }

  & .clear-button.btn-primary svg,
  & .clear-button.btn-primary svg path {
    background: none !important;
    color: var(--color-yellow-off) !important;
  }

  & hr {
    border-top: 1px solid white;
  }

  & #open-modal-button {
    background: transparent !important;
    border-color: var(--color-yellow) !important;
    border: solid;

    * {
      background-color: transparent !important;
    }

    strong {
      color: var(--color-yellow) !important;
    }

    img {
      filter: brightness(100%) sepia(10%) saturate(10000%);
    }
  }

  & #channelsCarouselWrapper .item .icon {
    background-color: var(--color-card-segment-card);
  }

  & .about-newway .knowledge-newway-button.btn.btn-custom,
  & .knowledge-newway-button.btn.btn-custom:hover,
  & .knowledge-newway-button.btn.btn-custom:active,
  & .knowledge-newway-button.btn.btn-custom:focus {
    border: none !important;
  }

  & .selo-img {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 200 200' style='enable-background:new 0 0 200 200;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAFE00;%7D%0A%3C/style%3E%3Cg id='Group_606' transform='translate(-503.712 -3620.75)'%3E%3Cpath id='Union_1' class='st0' d='M588.8,3803.3l-14.3,6.2l-9.8-16.4l-18.9,1.1l-2.9-15.3l-15.3-3.5l1.8-19l-16-10.3l6.6-14.1 l-10.3-11.7l12.5-14.3l-6.9-17.7l13.7-7.5l-1.4-15.5l18.6-4.2l4.8-18.3l15.5,2l8-13.4l17.5,7.5l14.7-12l11.3,10.7l14.3-6.2 l9.8,16.4l19-1.1l2.9,15.3l15.2,3.5l-1.7,18.9l16,10.3l-6.7,14.1l10.3,11.7l-12.6,14.3l6.9,17.7l-13.7,7.5l1.4,15.5l-18.6,4.2 l-4.9,18.5l-15.5-2l-8,13.4l-17.5-7.5l-14.8,12.1L588.8,3803.3z'/%3E%3Cpath d='M591.1,3751.5L591.1,3751.5c-2,0-3.9-0.8-5.3-2.2l-20.5-20.5c-2.9-2.9-2.9-7.7,0-10.6c2.9-2.9,7.7-2.9,10.6,0l15.2,15.2 l39.9-39.9c2.9-2.9,7.7-2.9,10.6,0c2.9,2.9,2.9,7.7,0,10.6l-45.2,45.2C595,3750.7,593.1,3751.5,591.1,3751.5z'/%3E%3C/g%3E%3C/svg%3E") !important;
  }

  & .segments-before-sections-bg-1,
  & .segments-before-sections-bg-2 {
    display: none !important;
  }

  & #segments-v2 #segments-section .segments-description {
    color: white;
  }
}
