:root {
  font-size: 60%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

img {
  width: 100%;
  align-self: flex-start;
  /* hack to safari height */
}

a,
a:hover,
a:active,
a:visited {
  color: inherit;
  text-decoration: none;
}

#root,
.App {
  display: flex;
  justify-content: center;
}

#root.fullscreen,
#root.fullscreen main#main-section {
  height: 100%;
}

#root:not(.fullscreen),
#root:not(.fullscreen) .App {
  align-items: center;
}

#root button {
  outline: none;
}

.hidden {
  display: none;
}

.force-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.App,
#app-wrapper {
  width: 100%;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-fade-exit {
  opacity: 0;
}

.transition-fade-exit-active {
  opacity: 0;
}

.background-template {
  background-repeat: no-repeat;
  background-size: 174rem;
  width: 100%;
  background-position: 0 0;
}

button:focus,
input:focus {
  outline: none;
}

.effect-hover-shadow-up {
  transition: all 0.5s;
}

.body-segments {
  background-image: none;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.body-institutional {
  background-image: url('https://d3q3w18r1z0nt6.cloudfront.net/images/pages/institutional/bg-institucional.png');
  background-repeat: no-repeat;
  overflow-x: hidden;
  background-position: 0;
  background-size: 261rem;
}

div.content-video {
  margin: 0 0 1rem;
}

div.anchor {
  position: absolute;
}

.inherit {
  all: inherit;
}

.modal-video {
  z-index: 16000010 !important;
}

.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
}

/**
* Keyframes
*/

@keyframes backgroundColorLoading {
  0% {
    background-color: #dfdfdf;
  }

  100% {
    background-color: var(--color-background-off);
  }
}

@keyframes loadingLabel {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes loadingTitle {
  0% {
    background-color: #dfdfdf;
  }

  100% {
    background-color: var(--color-background-off);
  }
}

@keyframes postContentLoading {
  0% {
    background-color: #dfdfdf;
  }

  100% {
    background-color: var(--color-background-off);
  }
}

/*===================*/
/* PULSE GROW
/*===================*/

@-webkit-keyframes pulse-grow-on-hover {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes pulse-grow-on-hover {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.pulse-grow-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.pulse-grow-on-hover:hover,
.pulse-grow-on-hover:focus,
.pulse-grow-on-hover:active {
  -webkit-animation-name: pulse-grow-on-hover;
  animation-name: pulse-grow-on-hover;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/**
* Medias
*/

@media (min-width: 768px) {
  .effect-hover-shadow-up:hover {
    transform: translateY(-3rem);
    box-shadow: 0 4rem 2rem var(--color-button-shadow);
  }
}

@media (min-width: 992px) {
  :root {
    font-size: 60.5%;
  }

  .background-template {
    background-position: right calc(100% - 200px) top;
  }

  .body-segments {
    background-image: url('https://d3q3w18r1z0nt6.cloudfront.net/images/pages/segments/background-childrens.png');
    background-position: right -400% top;
    background-size: 90%;
  }

  .segments-background-left {
    background: url('https://d3q3w18r1z0nt6.cloudfront.net/images/pages/segments/background-childrens-left.png');
    background-size: 55%;
    background-position: left -100% top 10%;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1000px) {
  .body-institutional {
    background-size: 220rem;
    background-position: top -400px right -500px;
  }
}

@media (min-width: 1500px) {
  .background-template {
    background-position: right -355px top;
  }

  .body-segments {
    background-position: 800px top;
    background-size: initial;
  }

  .segments-background-left {
    background: url('https://d3q3w18r1z0nt6.cloudfront.net/images/pages/segments/background-childrens-left.png');
    background-size: 60%;
    background-position: left -100% top 10%;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1740px) {
  .body-segments {
    background-position: right -500px top;
    background-size: initial;
  }

  .background-template {
    background-position: right -255px top;
  }
}

@media (min-width: 1920px) {
  .background-template {
    background-position: calc(100% + 350px) 0;
    background-size: initial;
  }

  .body-institutional {
    background-position: top -400px right -250px;
  }

  .segments-background-left {
    background-size: initial;
    background-position: left -30% top 10%;
  }
}

@media (min-width: 2300px) {
  .body-segments {
    background-position: right -200px top;
  }

  .body-institutional {
    background-position: top -400px right;
  }

  .segments-background-left {
    background-position: left -15% top 10%;
  }

  .background-template {
    background-position: 125% 0;
  }
}

@media (min-width: 2500px) {
  .background-template {
    background-position: 100% 0;
  }
}

@media (min-width: 3000px) {
  .body-segments {
    background-position: right top;
  }

  .body-institutional {
    background-position: top -400px right 250px;
  }

  .segments-background-left {
    background-position: left top 10%;
  }

  .background-template {
    background-position: 70% 0;
  }
}

/*
Hide Bricks
Hide Whatsapp Floating Button
 */
div[id^="bricks-component-"].rdstation-popup-position-bottom_right.floating-button.floating-button--close button[aria-label="Abrir WhatsApp"] {
  display: none !important;
  opacity: 0 !important;
  transform: scale(0) !important;
  left: -1000 !important;
  bottom: -1000 !important;
  z-index: 1 !important;
}